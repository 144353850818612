<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10  font-weight-bold text-dark">
      Delete Your Account <br />
      <small
        >Your account will be deleted once you initiate your request.</small
      >
    </h4>

    <div >
        <button
            ref="form_submit"
            class="btn btn-danger ml-0"
            @click="deleteMyAccount"
        >
            Delete My Account
        </button>
    </div>

  </div>
  <!--end::Card-->
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.pac-target-input {
  padding: 10px;
  width: 20%;
}
.btn {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: greenyellow;
}
</style>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "AccountDelete",
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  methods: {
    deleteMyAccount() {
        Swal.fire("Success", "Request Submitted.", "success");
        return;
    }
  }

};
</script>
